import { LocaleType } from '../Types/LocaleType'

export const LOCALES: LocaleType = {
  supportedLocale: ['en_us', 'ja_jp', 'fr_fr', 'de_de', 'zh-hans_cn', 'zh_cn'],
  supportedForwardLocale: [
    'en_us',
    'ja_jp',
    'fr_fr',
    'de_de',
    'zh-hans_cn',
    'zh_cn',
    'it_it',
    'ko_kr',
    'pt_br',
    'ru_ru',
    'es_es',
    'zh-hant_cn',
    'zh_tw'
  ],
  locale: {
    en_us: {
      PRODUCT_LOGIN: 'Product Login',
      SUPPORT_LOGIN: 'Support Login',
      CONTACT_SUPPORT: 'Contact Support',
      SPLUNK_INFRA_MONITORING:
        'Splunk Infrastructure Monitoring, APM, Log Observer and RUM',
      SPLUNK_PHANTOM: 'Splunk Phantom',
      PRIVACY: 'Privacy',
      WEBSITE_TERMS: 'Website Terms of Use',
      SPLUNK_LICENSING_TERMS: 'Splunk Licensing Terms',
      EXPORT_CONTROL: 'Export Control'
    },
    de_de: {
      PRODUCT_LOGIN: 'Produkt-Login',
      SUPPORT_LOGIN: 'Support-Login',
      CONTACT_SUPPORT: 'Support kontaktieren',
      SPLUNK_INFRA_MONITORING:
        'Splunk Infrastructure Monitoring, APM, Log Observer and RUM',
      SPLUNK_PHANTOM: 'Splunk Phantom',
      PRIVACY: 'Datenschutz',
      WEBSITE_TERMS: 'Nutzungsbedingungen',
      SPLUNK_LICENSING_TERMS: 'Lizenzbedingungen',
      EXPORT_CONTROL: 'Ausfuhrkontrolle'
    },
    fr_fr: {
      PRODUCT_LOGIN: 'Connexion au produit',
      SUPPORT_LOGIN: 'Connexion à l’assistance',
      CONTACT_SUPPORT: 'Contacter l’assistance',
      SPLUNK_INFRA_MONITORING:
        'Splunk Infrastructure Monitoring, APM, Log Observer and RUM',
      SPLUNK_PHANTOM: 'Splunk Phantom',
      PRIVACY: 'Confidentialité',
      WEBSITE_TERMS: 'Conditions d’utilisation du site web',
      SPLUNK_LICENSING_TERMS: 'Termes et conditions des licences Splunk',
      EXPORT_CONTROL: 'Contrôle des exportations'
    },
    ja_jp: {
      PRODUCT_LOGIN: '製品ログイン',
      SUPPORT_LOGIN: 'サポートログイン',
      CONTACT_SUPPORT: 'サポートに問い合わせる',
      SPLUNK_INFRA_MONITORING:
        'Splunk Infrastructure Monitoring, APM, Log Observer and RUM',
      SPLUNK_PHANTOM: 'Splunk Phantom',
      PRIVACY: 'プライバシー(英語)',
      WEBSITE_TERMS: '利用規約 (英語)',
      SPLUNK_LICENSING_TERMS: 'Splunk Licensing Terms',
      EXPORT_CONTROL: '輸出規制 (英語)'
    },
    'zh-hans_cn': {
      PRODUCT_LOGIN: '产品登录',
      SUPPORT_LOGIN: '技术支持门户登录',
      CONTACT_SUPPORT: '联系我们',
      SPLUNK_INFRA_MONITORING:
        'Splunk Infrastructure Monitoring, APM, Log Observer and RUM',
      SPLUNK_PHANTOM: 'Splunk Phantom',
      PRIVACY: '隐私',
      WEBSITE_TERMS: '使用条款',
      SPLUNK_LICENSING_TERMS: 'Splunk Licensing Terms',
      EXPORT_CONTROL: '出口管制'
    },
    zh_cn: {
      PRODUCT_LOGIN: '产品登录',
      SUPPORT_LOGIN: '技术支持门户登录',
      CONTACT_SUPPORT: '联系我们',
      SPLUNK_INFRA_MONITORING:
        'Splunk Infrastructure Monitoring, APM, Log Observer and RUM',
      SPLUNK_PHANTOM: 'Splunk Phantom',
      PRIVACY: '隐私',
      WEBSITE_TERMS: '使用条款',
      SPLUNK_LICENSING_TERMS: 'Splunk Licensing Terms',
      EXPORT_CONTROL: '出口管制'
    }
  }
}
