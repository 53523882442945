import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { RetrieveUsername } from '../Components/RetrieveUsername'
import { LostPassword } from '../Components/LostPassword/LostPassword'
import { ResetPassword } from '../Components/ResetPassword/ResetPassword'
import { ForcedResetPassword } from '../Components/ResetPassword/ForcedResetPassword'
import { Logout } from '../Components/Logout'
import { Login } from '../Components/Login/Login'
import { Unauthorized } from '../Components/Responses/Unauthorized'
import { ServerError } from '../Components/Responses/ServerError'
import { NotFound } from '../Components/Responses/NotFound'
import { BadRequest } from '../Components/Responses/BadRequest'
import { InvalidToken } from '../Components/Responses/InvalidToken'
import { EmailVerify } from '../Components/EmailVerify'
import { useAppSelector } from '../Redux/Store'
import { useGetWhitelistedDomainsQuery } from '../Redux/Apis/LoginApi'
import {
  getLocale,
  includeEmbargoScript,
  localeFooterLinks,
  startRUM,
  setCopyrightText
} from '../Utils/Utils'

export function CreateRoutes() {
  useGetWhitelistedDomainsQuery('')
  const { allowedDomains, message, isLoggedIn } = useAppSelector(
    (state) => state.login
  )
  const { localeData, receivedLocale } = getLocale()

  useEffect(() => {
    localeFooterLinks(localeData, receivedLocale)
    includeEmbargoScript()
  }, [localeData, receivedLocale])

  useEffect(() => {
    startRUM()
    setCopyrightText()
  }, [])

  return (
    <Routes>
      <Route index element={<Login />} />
      <Route
        path="/:locale/page/retrieve_username"
        element={<RetrieveUsername />}
      />
      <Route
        path="/:locale/page/lost_password"
        element={<LostPassword message={message} />}
      />
      <Route
        path="/page/lost_password"
        element={<LostPassword message={message} />}
      />
      <Route
        path="/:locale/page/lost2_password/"
        element={<ForcedResetPassword />}
      />
      <Route
        path="/:locale/page/lost_password/reset"
        element={<ResetPassword />}
      />
      <Route path="/page/lost_password/reset" element={<ResetPassword />} />
      <Route
        path="/logout"
        element={
          <Logout allowedDomains={allowedDomains} loggedIn={isLoggedIn} />
        }
      />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/server_error" element={<ServerError />} />
      <Route path="/not_found" element={<NotFound />} />
      <Route path="/bad_request" element={<BadRequest />} />
      <Route path="/email_verify" element={<EmailVerify />} />
      <Route path="/invalid_token" element={<InvalidToken />} />
      <Route path="/:locale" element={<Login />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

export default CreateRoutes
