import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ROOT, USER_ALREADY_LOGGED_IN } from '../../Constants/Constants'

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }),
  tagTypes: ['WhitelistedDomains', 'isUserLoggedIn'],
  endpoints: (builder) => ({
    isUserLoggedIn: builder.query({
      query: () => ({
        url: `/session/info`,
        method: 'GET'
      }),
      transformResponse: (response: any) =>
        response.data === USER_ALREADY_LOGGED_IN,
      providesTags: ['isUserLoggedIn']
    }),
    getWhitelistedDomains: builder.query({
      query: () => ({
        url: `/whitelisteddomain`,
        method: 'GET'
      }),
      transformResponse: (response: any) =>
        response.data.allowed_domains.split(','),
      providesTags: ['WhitelistedDomains']
    }),
    validateUserEmail: builder.query({
      query: (email: string) => ({
        url: `/user/profile/${encodeURIComponent(email)}`,
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data,
      providesTags: []
    }),
    csrfToken: builder.query({
      query: () => ({
        url: '/auth/csrfToken',
        method: 'GET'
      }),
      // eslint-disable-next-line @typescript-eslint/dot-notation
      transformResponse: (response: any) => response['_csrf'],
      providesTags: []
    }),
    sendLostPasswordEmail: builder.mutation({
      query: ({ email, recaptchaToken }) => ({
        url: `/user/forgot-password/${encodeURIComponent(email)}`,
        method: 'POST',
        body: {
          recaptchaToken,
          search: window.location.search
        }
      }),
      transformResponse: (response: any) => response.data
    }),
    sendForgotUsernameEmail: builder.mutation({
      query: ({ email, recaptchaToken }) => ({
        url: `/user/forgot-username/${encodeURIComponent(email)}`,
        method: 'POST',
        body: { search: window.location.search, recaptchaToken }
      })
    }),
    changePassword: builder.mutation({
      query: ({ password, token }) => ({
        url: '/reset-password-with-token',
        method: 'POST',
        body: { newPassword: password, recoveryToken: token }
      }),
      invalidatesTags: []
    }),
    forceChangePassword: builder.query({
      query: ({ login, oldPassword, newPassword }) => ({
        url: '/reset-password-with-token',
        method: 'PUT',
        body: {
          login,
          old_password: oldPassword,
          new_password: newPassword
        }
      }),
      transformResponse: (response: any) => response.data,
      providesTags: []
    }),
    validateRecoveryToken: builder.mutation({
      query: (recoveryToken: string) => ({
        url: '/token-validation',
        method: 'POST',
        body: { recoveryToken }
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: []
    }),
    sendAccountActivationEmail: builder.query({
      query: (email: string) => ({
        url: `/user/resend-activation/${encodeURIComponent(email)}`,
        method: 'POST',
        body: {}
      }),
      transformResponse: (response: any) => response.data,
      providesTags: []
    }),
    deleteSSOId: builder.mutation({
      query: ({ ssosid, myaccountSid }) => ({
        url: `/session/${ssosid || null}/${myaccountSid || null}`,
        method: 'DELETE'
      }),
      transformResponse: (response: any) => response.data
    })
  })
})

export const {
  useCsrfTokenQuery,
  useIsUserLoggedInQuery,
  useChangePasswordMutation,
  useDeleteSSOIdMutation,
  useForceChangePasswordQuery,
  useGetWhitelistedDomainsQuery,
  useSendAccountActivationEmailQuery,
  useSendForgotUsernameEmailMutation,
  useValidateRecoveryTokenMutation,
  useValidateUserEmailQuery,
  useSendLostPasswordEmailMutation
} = loginApi
