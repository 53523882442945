import React, { useState } from 'react'
import { getQueryString } from 'src/Utils/Utils'
import { LostPasswordFormType } from '../../Types/LostPasswordFormType'
import { pushToDL } from '../../Utils/GTM'
import { Captcha } from '../Captcha/Captcha'

export default function LostPasswordFormComponent(props: LostPasswordFormType) {
  const { emailChange, handleSubmit, dataLayerData, actualCallback } = props
  const searchParams: any = getQueryString()
  const [email, setEmail] = useState(searchParams.username || '')
  const [enableSubmit, setEnableSubmit] = useState(false)
  const [enableCaptchaSubmit, setEnableCaptchaSubmit] = useState(true)

  const onSubmit = () => {
    if (!email || !email.length) {
      return setEnableSubmit(false)
    }
    handleSubmit()
  }
  const handleUpdates = (value: any) => {
    setTimeout(() => {
      setEmail(value)
      emailChange(value)
      setEnableSubmit(value && value.length)
    })
  }
  const handleKeydown = (event: any) => {
    if (!email || !email.length) {
      pushToDL('form_start', dataLayerData)
    }

    handleUpdates(event.target.value || '')

    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <div>
      <label htmlFor="email">Email or Username</label>
      <input
        type="text"
        className="inputBox form-control"
        name="email"
        id="email"
        size={25}
        maxLength={255}
        value={email}
        onChange={(e: any) => {
          handleUpdates(e.target.value)
        }}
        onInput={(e: any) => {
          handleUpdates(e.target.value)
        }}
        onBlur={(e: any) => {
          handleUpdates(e.target.value)
        }}
        onFocus={() => {
          pushToDL('form_field_interaction', dataLayerData)
        }}
        onKeyDown={handleKeydown}
      />
      <Captcha
        loginCallback={actualCallback}
        setEnableSubmit={setEnableCaptchaSubmit}
      />
      <input
        type="submit"
        className="btn submit-btn btn-bottom-space"
        value="Reset Password"
        disabled={!enableSubmit || !enableCaptchaSubmit}
        onClick={() => onSubmit()}
      />
    </div>
  )
}
