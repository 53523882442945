import { MARKETING_CONFIG, REGEX } from '../Constants/Constants'
import { pushToDL } from './GTM'

export const addLinkEventsToDL = (
  e: Event,
  category: string,
  container: string,
  componentName: string,
  isImg: boolean,
  title: string
) => {
  // @ts-ignore
  const { textContent } = e.target
  const clickText =
    isImg && textContent.trim() === '' ? title : textContent.trim()
  // @ts-ignore
  const { href } = e.target.closest('a') || { href: undefined }

  const linkDomainMatch = href && href.match(/^https?:\/\/([^/?#]+)/)
  const linkUrlMatch =
    href && href.match(/^https?:\/\/([^/?#]+\.[^/?#]+\/[^?#]+)/)
  const linkUrl = linkUrlMatch ? linkUrlMatch[1] : undefined
  const linkDomain = linkDomainMatch ? linkDomainMatch[1] : undefined

  pushToDL('link_click', {
    linkText: clickText,
    linkDomain,
    linkUrl: linkUrl ?? linkDomain,
    linkPosition: container
  })
  pushToDL('linkClick', {
    category,
    component: `${container}_${clickText}`,
    clickUrl: window.location.href,
    destinationUrl: linkUrl ?? linkDomain,
    container,
    componentName
  })
}

export const load = (url: string, errorCallback: Function) => {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()

    req.open('GET', url)

    req.onload = () => {
      if (req.status === 200) {
        resolve(req.responseText)
      } else {
        reject(new Error(`Failed to fetch data from ${url}`))
      }
    }

    req.onerror = () => {
      resolve(errorCallback())
    }

    req.send()
  })
}

export const getMarketingContent = () => {
  const { content, baseUrl, analyticsSelector } = MARKETING_CONFIG
  try {
    Object.keys(content).forEach(async (k) => {
      const {
        path,
        container,
        linkPositionContainer,
        componentName,
        accessibilityIndex,
        errorCallback
      } = content[k]
      const slot = `${k}-slot`
      const url = `${baseUrl}/${path}`
      const containerEl = document.querySelector(`div.${container}`)
      const child = document.createElement('div')
      containerEl?.appendChild(child)
      child.setAttribute('class', 'hideBanner')
      const mktSlotEl = document.createElement('div')
      mktSlotEl.setAttribute('class', slot)

      const rawHtml = await load(url, errorCallback)

      if (rawHtml) {
        mktSlotEl.innerHTML = (rawHtml as unknown as string).replace(
          REGEX.removeAllJavascript,
          ''
        )
        child.append(mktSlotEl)
        child.removeAttribute('class')

        // GTM layer
        const selector = analyticsSelector.join(`, .${slot} `)
        const wwwLinks = document.querySelectorAll(`.${slot} ${selector}`)

        wwwLinks.forEach((el, index) => {
          el.setAttribute('target', '_blank')
          el.setAttribute('tabindex', String(index + accessibilityIndex))
          let title: string
          let isImg = false
          const imgElement = el.querySelector('img')
          if (imgElement) {
            isImg = true
            const { src } = imgElement
            const filename = src.substring(src.lastIndexOf('/') + 1)
            title = `image: ${filename.replace(/\.[^/.]+$/, '')}`
          }
          el.addEventListener('click', (e) =>
            addLinkEventsToDL(
              e,
              'All clicks on CTA Text',
              linkPositionContainer,
              componentName,
              isImg,
              title
            )
          )
        })
      }
    })
  } catch (er) {
    // eslint-disable-next-line no-console
    console.error('Unable to fetch www content')
  }
}

export default getMarketingContent
