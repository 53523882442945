import React, { useRef, useState } from 'react'
import { ResetPasswordFormType } from '../../Types/ResetPasswordFormType'
import { ResetPasswordTooltip } from './ResetPasswordTooltip'
import { pushToDL } from '../../Utils/GTM'
import EyeOpenIcon from '../../Images/eye-open.svg'
import EyeClosedIcon from '../../Images/eye-close.svg'

export default function ResetPasswordFormComponent(
  props: ResetPasswordFormType
) {
  const {
    showTooltip: popup,
    password,
    handlePasswordChange,
    handleSubmit
  } = props
  const [showTooltip, setShowTooltip] = useState(popup)
  const [isShowingPassword, setIsShowingPassword] = useState(false)
  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }

  const EyeIcon = isShowingPassword ? EyeClosedIcon : EyeOpenIcon
  const inputType = isShowingPassword ? 'text' : 'password'

  // ref for the input element
  const inputRef = useRef<HTMLInputElement>(null)

  const toggleShowPassword = () => {
    setIsShowingPassword(!isShowingPassword)
    inputRef.current?.focus()
  }

  return (
    <div>
      <div>
        <label htmlFor="email">New Password</label>
        <div>
          <div className="resetPasswordTooltipPosition">
            <div
              style={{
                position: 'relative'
              }}
            >
              <input
                ref={inputRef}
                type={inputType}
                className="inputBox form-control"
                name="email"
                id="password"
                size={25}
                maxLength={255}
                onChange={handlePasswordChange}
                onKeyDown={handleEnter}
                onFocus={() => {
                  setShowTooltip(true)
                }}
                onBlur={() => {
                  pushToDL('form_field_interaction', {
                    fieldName: 'email',
                    formType: 'reset password'
                  })
                  setShowTooltip(true)
                }}
                style={{
                  paddingRight: '50px'
                }}
              />
              <button
                onClick={toggleShowPassword}
                type="button"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  visibility: password.length > 0 ? 'visible' : 'hidden'
                }}
              >
                <img src={EyeIcon} alt="show-hide-password" />
              </button>
            </div>
            <ResetPasswordTooltip
              showTooltip={showTooltip}
              password={password}
              handlePasswordChange={handlePasswordChange}
            />
          </div>
        </div>
        <div>
          <input
            type="submit"
            className="btn submit-btn btn-bottom-space"
            value="Reset Password"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}
