import React from 'react'

export function ServerError() {
  return (
    <div className="why-splunk">
      <div className="bg-position">&nbsp;</div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <header className="heading-section">
              <h2
                style={{
                  color: '#374147',
                  paddingTop: '50px',
                  paddingBottom: '0'
                }}
              >
                Oops!
              </h2>
              <h3
                style={{
                  color: '#374147',
                  fontSize: '25px',
                  borderBottom: '1px solid #ccc',
                  paddingBottom: '25px'
                }}
              >
                500 Error: The page cannot be displayed.
              </h3>
              <p style={{ paddingBottom: '0', paddingTop: '20px' }}>
                Please refresh the page to try again. If the error persists,
                &nbsp;
                <a
                  href="/content/splunkcom/en_us/about-us/contact.html"
                  style={{ margin: '0' }}
                >
                  please let us know
                </a>
              </p>
              <p style={{ paddingBottom: '0' }}>
                Or you can start over at the &nbsp;
                <a href="/content/homepage-broken.html" style={{ margin: '0' }}>
                  home page
                </a>
              </p>
            </header>
          </div>
        </div>
      </div>
    </div>
  )
}
