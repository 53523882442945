export const pushToDL = (event: string, values: any) => {
  const baseObj = {
    form_name: values.formType,
    form_type: values.formType,
    is_modal: false
  }
  const eventsList: any = {
    form_start: () => {
      const timerStart = `${values.fieldName}_start`
      window[timerStart] = Date.now()
      return {
        ...baseObj,
        event: 'form_start',
        field_name: values.fieldName
      }
    },
    form_field_interaction: () => {
      return {
        ...baseObj,
        event: 'form_field_interaction',
        field_name: values.fieldName
      }
    },
    form_submit_success: () => {
      const start = window[`${values.fieldName}_start`] || window.formStart
      return {
        ...baseObj,
        event: 'form_submit_success',
        time_to_complete: (Date.now() - start) / 1000
      }
    },
    form_submit_error: () => {
      const start = window[`${values.fieldName}_start`] || window.formStart

      return {
        ...baseObj,
        event: 'form_submit_error',
        time__to_complete: (Date.now() - start) / 1000,
        error_message: values.errorMessage
      }
    },
    link_click: () => {
      return {
        event: 'link_click',
        link_text: values.linkText,
        link_domain: values.linkDomain,
        link_url: values.linkUrl,
        link_position: values.linkPosition
      }
    },
    linkClick: () => {
      return {
        event: 'linkClick',
        eventInfo: { ...values }
      }
    }
  }

  if (eventsList[event]) {
    window.dataLayer.push(eventsList[event]())
  }
}
