/* eslint-disable @typescript-eslint/indent */
import React from 'react'
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import {
  Provider,
  useSelector,
  useDispatch,
  TypedUseSelectorHook
} from 'react-redux'

import { loginSlice } from './Slices/LoginSlice'
import { loginApi } from './Apis/LoginApi'

const { increment, clear } = loginSlice.actions

export const store = configureStore({
  reducer: {
    login: loginSlice.reducer,
    [loginApi.reducerPath]: loginApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loginApi.middleware)
})

export function useStore() {
  const dispatch = useDispatch()
  // const count = useSelector((state: any) => state)
  const allowedDomains = useSelector((state: any) => state.login.allowedDomains)

  return {
    // count,
    allowedDomains,
    increment: () => dispatch(increment()),
    clear: () => dispatch(clear())
  }
}

export const useAppDispatch = () => useDispatch()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
export type IStoreProviderProps = { children: any }
export function StoreProvider({ children }: IStoreProviderProps) {
  return <Provider store={store}> {children}</Provider>
}
