import React, { useState, useEffect } from 'react'
import {
  RECAPTCHA_SCRIPT_URL,
  HCAPTCHA_SCRIPT_URL,
  HCAPTCHA_COUNTRY_CODES,
  GOOGLE_RECAPTCHA_SITE_KEY,
  HCAPTCHA_SITE_KEY,
  GEOIP_URL
} from 'src/Constants/Constants'

export function Captcha(props: any) {
  const { loginCallback, setEnableSubmit = () => {} } = props
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [countryCode, setCountryCode] = useState(null)
  const [siteKey, setSiteKey] = useState('')

  window.gRecaptchaCallback = loginCallback

  const loadScript = async (url: string) => {
    if (scriptLoaded) {
      return
    }

    setScriptLoaded(true)

    if (url.includes('hcaptcha')) {
      setEnableSubmit(false)
      setSiteKey(HCAPTCHA_SITE_KEY)
      window.hCaptchaCallback = () => {
        setEnableSubmit(true)
      }
      window.hCaptchaExpiredCallback = () => {
        window.hcaptcha.reset()
      }
    } else {
      setSiteKey(GOOGLE_RECAPTCHA_SITE_KEY)
      setEnableSubmit(true)
    }
    const script = document.createElement('script')
    script.src = url
    script.async = true
    script.defer = true
    document.head.appendChild(script)
  }
  const getGeoIp = async () => {
    const response = await fetch(GEOIP_URL)
    const { data } = await response.json()

    return data
  }
  useEffect(() => {
    if (!scriptLoaded) {
      getGeoIp()
        .then(async (res: any) => {
          const { countryCode: cc } = res
          setCountryCode(countryCode)
          const url = HCAPTCHA_COUNTRY_CODES.includes(cc)
            ? HCAPTCHA_SCRIPT_URL
            : RECAPTCHA_SCRIPT_URL
          await loadScript(url)
        })
        .catch(async () => {
          await loadScript(RECAPTCHA_SCRIPT_URL)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded, countryCode])

  return (
    <>
      <div
        className="g-recaptcha"
        data-callback="gRecaptchaCallback"
        data-size="invisible"
        data-sitekey={siteKey}
      />
      <div
        className="h-captcha"
        id="h-captcha"
        data-callback="hCaptchaCallback"
        data-expired-callback="hCaptchaExpiredCallback"
        data-sitekey={siteKey}
      />
    </>
  )
}
