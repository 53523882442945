import React from 'react'

import { TopLine } from './TopLine'

export function Container(props: any) {
  const { children } = props

  return (
    <div id="content">
      <section className="splunk-login">
        <div className="container">
          <div className="row">
            <div className="splunk-login-white-box">
              <TopLine />
              {children}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
