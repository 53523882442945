import React, { useEffect, useState } from 'react'

import { validateRedirectUrl } from '../Utils/Router'
import { deleteOktaSession } from '../Utils/Okta'
import { deletAuthCookies, readCookie } from '../Utils/Utils'
import { LogoutType } from '../Types/LogoutType'
import { useDeleteSSOIdMutation } from '../Redux/Apis/LoginApi'
import { TopLine } from '../Shared/TopLine'

export function Logout(props: LogoutType) {
  const { allowedDomains } = props
  const [ssosid] = useState(readCookie('SSOSID'))
  const [myaccountSid] = useState(readCookie('myaccount.sid'))
  const [deleteSSOId] = useDeleteSSOIdMutation()

  useEffect(() => {
    const { redirectUrl } = validateRedirectUrl(allowedDomains, '', false)

    const logoutSession = async () => {
      await deleteOktaSession()
      await deleteSSOId({ ssosid, myaccountSid })
      deletAuthCookies(redirectUrl)
    }
    logoutSession().catch(() => deletAuthCookies(redirectUrl))
  }, [allowedDomains, deleteSSOId, ssosid, myaccountSid])

  return (
    <div id="content">
      <section className="splunk-login">
        <div className="container">
          <div className="row">
            <div className="splunk-login-white-box">
              <TopLine />
              <h1>Logging out...</h1>
              <div id="loginMsg" className="login-alert-msg" />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
