import { getDomain } from 'tldts'
import { REDIRECT_PARAMS, REGEX, URLS } from '../Constants/Constants'

export const validateQueryParams = (
  url: URL,
  searchParams: any,
  shouldReload: boolean,
  queryParams: any
) => {
  let reloadIfAllowed = false
  queryParams.forEach((queryParam: string) => {
    if (searchParams.has(queryParam)) {
      const trimmedParam = searchParams.get(queryParam).trim()
      if (!trimmedParam || !trimmedParam.length) {
        searchParams.delete(queryParam)
        reloadIfAllowed = shouldReload
      }
    }
  })

  if (reloadIfAllowed) {
    window.location.href = url.toString()
  }
}

export const validURL = (url: string) => {
  // eslint-disable-next-line prefer-regex-literals
  const pattern = new RegExp(REGEX.validateUrl, 'i')
  return pattern.test(url)
}
export const getRedirectParamKey = (searchParams: URLSearchParams) =>
  Array.from(searchParams.keys()).find((k: string) =>
    REDIRECT_PARAMS.includes(k)
  )

export const validateRedirectUrl = (
  allowedDomains: Array<string>,
  receivedLocale: string,
  shouldReload: boolean
) => {
  let urlparameter
  const url = new URL(window.location.href)
  const { searchParams } = url
  const defaultRedirectUrl = URLS.DEFAULT_REDIRECT_URL || ''

  validateQueryParams(url, searchParams, shouldReload, REDIRECT_PARAMS)
  const redirectParam = getRedirectParamKey(searchParams)
  if (redirectParam) {
    const potentialURLParameter = searchParams.get(redirectParam) || ''

    if (validURL(potentialURLParameter)) {
      urlparameter = potentialURLParameter
    } else {
      searchParams.set(redirectParam, defaultRedirectUrl)
      if (shouldReload) {
        window.location.href = `${
          url.origin
        }/${receivedLocale}?${searchParams.toString()}`
      }
    }
  }

  if (!urlparameter) {
    urlparameter = defaultRedirectUrl
  }

  const redirectURL = new URL(urlparameter)
  const domainToTest = getDomain(redirectURL.toString())
  const domaintToTestIsInAllowedDomains = allowedDomains.some(
    (d: string) => domainToTest === d
  )

  if (domaintToTestIsInAllowedDomains) {
    const { searchParams: redirectSearchParams } = redirectURL
    let redirectFromURI = redirectURL.toString()

    if (redirectSearchParams.has('fromURI')) {
      redirectFromURI = redirectSearchParams.get('fromURI') || ''
    }

    return {
      redirectUrl: urlparameter,
      fromURI: encodeURIComponent(redirectFromURI)
    }
  }

  if (shouldReload) {
    return {
      redirectUrl: `${url.origin}/${receivedLocale}/?redirecturl=${defaultRedirectUrl}`
    }
  }

  return {
    redirectUrl: defaultRedirectUrl,
    fromURI: defaultRedirectUrl
  }
}
