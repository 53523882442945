/* eslint-disable @typescript-eslint/no-unused-vars,no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { LoginStateType } from '../../Types/LoginStateType'
import { loginApi } from '../Apis/LoginApi'

const initialState: LoginStateType = {
  count: 0,
  userType: undefined,
  message: '',
  password: '',
  ssosid: '',
  allowedDomains: ['splunk.com'],
  isLoggedIn: false
}
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    increment: (state) => {
      state.count += 1
    },
    clear: (state) => {
      state.count = 0
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      loginApi.endpoints.getWhitelistedDomains.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          allowedDomains: [...state.allowedDomains, ...payload]
        }
      }
    )

    builder.addMatcher(
      loginApi.endpoints.isUserLoggedIn.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          isLoggedIn: payload
        }
      }
    )
  }
})

export const { increment, clear } = loginSlice.actions

export default loginSlice.reducer
