import React, { useState } from 'react'
import { URLS } from '../../Constants/Constants'

const useUserHasSubmittedFeedback = () => {
  const localStorageKey = 'feedbackSubmitted'
  const localStorageValue = localStorage.getItem(localStorageKey)
  const setFeedbackSubmitted = (value: boolean) =>
    localStorage.setItem(localStorageKey, JSON.stringify(value))
  return {
    feedbackSubmitted: localStorageValue === 'true',
    setFeedbackSubmitted
  }
}

export function FeedbackPopup() {
  const { feedbackSubmitted, setFeedbackSubmitted } =
    useUserHasSubmittedFeedback()
  const [show, setShow] = useState(!feedbackSubmitted)

  const onClickClose = () => {
    setShow(false)
    setFeedbackSubmitted(true)
  }

  if (!show) return null
  return (
    <div className="feedback-popup">
      <a
        href={URLS.CSAT_SURVEY_URL}
        target="_blank"
        rel="noreferrer"
        onClick={onClickClose}
      >
        <div className="popup-link">Feedback</div>
      </a>
      <button type="button" className="close-button" onClick={onClickClose}>
        <span className="icon-close" />
      </button>
    </div>
  )
}
