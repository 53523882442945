import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOMClient from 'react-dom/client'
import { StoreProvider } from './Redux/Store'
import { CreateRoutes } from './Routing/Routing'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Styles/css/v6.css'
import './Styles/fonts.scss'
import './Styles/style.scss'
import './Styles/Button.scss'
import './index.scss'

export function Wrapper() {
  return (
    <StoreProvider>
      <BrowserRouter>
        <CreateRoutes />
      </BrowserRouter>
    </StoreProvider>
  )
}

ReactDOMClient.createRoot(document.getElementById('app') as HTMLElement).render(
  <Wrapper />
)
