import { URLS } from 'src/Constants/Constants'

export const deleteOktaSession = async () => {
  try {
    await fetch(`${URLS.OKTA_HOST}/api/v1/sessions/me`, {
      method: 'DELETE',
      credentials: 'include'
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `There was an error while deleting the session: ${JSON.stringify(error)}`
    )
  }
}

export const checkSessionMe = async () => {
  const response = await fetch(`${URLS.OKTA_HOST}/api/v1/sessions/me`, {
    credentials: 'include',
    mode: 'cors'
  })

  return response.json()
}
