import styled from 'styled-components'
import { MEDIA } from '../../Constants/Constants'

export const Title = styled.span`
  position: static;
  width: 460px;
  height: 23px;
  left: 0px;
  top: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 110%;
  text-align: center;
  color: ${(props) => props.color};
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;

  @media (max-width: ${MEDIA.MAX_WIDTH}) {
    width: 279px;
    height: 46px;
  }
`
export const Bar = styled.div`
  position: absolute;
  width: 540px;
  left: calc(50% - 540px / 2);
  top: 0%;
  bottom: 98.78%;
  background: #ed0080;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;

  @media (max-width: ${MEDIA.MAX_WIDTH}) {
    width: 343px;
    left: calc(50% - 343px / 2);
    bottom: 98.77%;
  }
`
export const Icon = styled.img`
  position: relative;
  width: 460px;
  height: 80px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;

  @media (max-width: ${MEDIA.MAX_WIDTH}) {
    position: static;
    width: 80px;
    left: 100px;
    top: 0.18px;
  }
`

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 540px;
  height: 393.82px;
  left: calc(50% - 540px / 2);
  top: 80px;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14), 0px 12px 40px rgba(0, 0, 0, 0.15);

  @media (max-width: ${MEDIA.MAX_WIDTH}) {
    width: 343px;
    height: 392.82px;
    left: calc(50% - 343px / 2 - 1px);
    top: 20px;
  }
`

export const SplunkLogo = styled.img`
  position: relative;
  width: 460px;
  height: 36px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;

  @media (max-width: ${MEDIA.MAX_WIDTH}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: static;
    width: 279px;
    left: 32px;
    top: 40px;
    margin: 10px 0px;
  }
`

export const Message = styled.span`
  position: static;
  width: 460px;
  height: 50px;
  left: 0px;
  top: 39px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  text-align: center;
  color: #0c1724;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  @media (max-width: ${MEDIA.MAX_WIDTH}) {
    width: 279px;
    height: 66px;
    top: 62px;
    font-size: 16px;
  }
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px;
  position: static;
  width: 540px;
  height: 389px;
  left: 0px;
  top: 4.82px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;

  @media (max-width: ${MEDIA.MAX_WIDTH}) {
    padding: 40px 32px;
    width: 343px;
    height: 388px;
    align-self: stretch;
  }
`
