import React, { useEffect, useState } from 'react'

import {
  useCsrfTokenQuery,
  useSendForgotUsernameEmailMutation
} from '../Redux/Apis/LoginApi'
import { MESSAGES } from '../Constants/Constants'
import { pushToDL } from '../Utils/GTM'

import {
  addLinkEventsToDL,
  getEmailValidationMessage,
  isValidEmail,
  submitWithCaptcha
} from '../Utils/Utils'
import { Container } from '../Shared/Container'
import { Captcha } from './Captcha/Captcha'

export function RetrieveUsername(props: any) {
  const { email: emailProp } = props
  const formType = 'forgot username'
  const [email, setEmail] = useState(emailProp)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [csrf, setCSRF] = useState('')
  const { data: csrfToken, isLoading } = useCsrfTokenQuery('')
  const [enableCaptchaSubmit, setEnableCaptchaSubmit] = useState(true)

  const [message, setMessage] = useState('')
  const [succesMessage, setSuccessMessage] = useState('')
  const [showNextBox, setShowNextBox] = useState(false)
  const [sendForgotUsernameEmail] = useSendForgotUsernameEmailMutation()

  useEffect(() => {
    document.title = 'Recover Your Username | Splunk'
    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.setAttribute(
        'content',
        'Recover the username for your Splunk account.'
      )
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setCSRF(csrfToken)
    }
  }, [csrfToken, isLoading])

  const handleEmailFocus = () => {
    pushToDL('form_field_interaction', { fieldName: 'email', formType })
  }

  const addEventToLoginLink = (e: any) => {
    addLinkEventsToDL(e, 'All clicks on Login CTA', 'loginBtn', 'text')
  }

  const handleChange = (event: any) => {
    if (!window.formStart) {
      window.formStart = Date.now()
      pushToDL('form_start', { fieldName: 'email', formType })
    }
    setEmail(event.target.value)
  }

  const sendActualRetrieveUsername = async (recaptchaToken: string) => {
    const { data = {} }: any = await sendForgotUsernameEmail({
      email,
      recaptchaToken
    })

    if (data.status === 'success') {
      setShowNextBox(true)
      setMessage('')
      return setSuccessMessage(MESSAGES.validUser)
    }

    return setMessage(MESSAGES.serverError)
  }

  const handleSubmit = async () => {
    const isInvalid = getEmailValidationMessage(email)

    if (isInvalid) {
      const { message: msg, dlMessage: errorMessage } = isInvalid
      pushToDL('form_submit_error', { errorMessage, formType })
      setShowNextBox(msg === 'employeeRetrieveUsername')

      return setMessage(MESSAGES[msg])
    }

    await submitWithCaptcha(sendActualRetrieveUsername)
  }

  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  let displayMessage = null
  let loginBox = (
    <>
      <label htmlFor="email">
        E-mail address
        <input
          type="text"
          className="inputBox form-control"
          name="email"
          id="email"
          size={25}
          maxLength={255}
          onChange={handleChange}
          onKeyUp={handleEnter}
          onFocus={handleEmailFocus}
        />
      </label>
      <div>
        <Captcha
          loginCallback={sendActualRetrieveUsername}
          setEnableSubmit={setEnableCaptchaSubmit}
        />
        <input
          type="button"
          className="btn submit-btn btn-bottom-space"
          value="Email my Username"
          onClick={handleSubmit}
          disabled={!isValidEmail(email) || !enableCaptchaSubmit}
        />
      </div>
    </>
  )

  if (showNextBox) {
    loginBox = (
      <a href="/" onClick={(e) => addEventToLoginLink(e)}>
        <input
          type="button"
          className="btn submit-btn"
          name="Login"
          value="Log In"
        />
      </a>
    )
  }

  if (message && message.length) {
    displayMessage = <p id="message">{message}</p>
  } else if (succesMessage && succesMessage.length) {
    displayMessage = <p>{succesMessage}</p>
  }

  return (
    <Container>
      <h1>Recover Username</h1>
      <div>
        <div>
          {displayMessage}
          {loginBox}
        </div>
      </div>
    </Container>
  )
}
