import React, { useEffect, useState } from 'react'

import { MESSAGES } from 'src/Constants/Constants'
import { pushToDL } from '../../Utils/GTM'
import {
  addLinkEventsToDL,
  getTokenFromURL,
  meetsAllRequirements
} from '../../Utils/Utils'
import ResetPasswordFormComponent from './ResetPasswordFormComponent'
import { LostPassword } from '../LostPassword/LostPassword'
import { useAppSelector } from '../../Redux/Store'
import {
  useChangePasswordMutation,
  useValidateRecoveryTokenMutation
} from '../../Redux/Apis/LoginApi'
import { Container } from '../../Shared/Container'

export function ResetPassword() {
  const { password: pwd } = useAppSelector((state) => state.login)
  const [isValidRecoveryToken] = useValidateRecoveryTokenMutation()
  const [changePassword] = useChangePasswordMutation()
  const token = getTokenFromURL()
  const dataLayerData = { formType: 'reset password', fieldName: 'password' }
  const [showTooltip, setShowTooltip] = useState(false)
  const [isTokenValid, setIsTokenValid] = useState(false)
  const [inFlight, setInFlight] = useState(true)
  const [tokenValidationDone, setTokenValidationDone] = useState(false)
  const [successfullyReset, setSuccessfullyReset] = useState(false)
  const [message, setMessage] = useState('')
  const [defaultMessage, setDefaultMessage] = useState(
    MESSAGES.enterNewPassword
  )
  const [password, setPassword] = useState(pwd)

  useEffect(() => {
    if (token && !tokenValidationDone) {
      const validateToken = async () => {
        setMessage('')
        const { data = {} }: any = await isValidRecoveryToken(token)
        const isValid = data.status === 'success'
        setInFlight(false)
        setTokenValidationDone(true)
        setMessage(isValid ? '' : MESSAGES.expiredLink)
        setIsTokenValid(isValid)
      }

      validateToken().catch(() => {
        setInFlight(false)
        setTokenValidationDone(true)
        setIsTokenValid(false)
        setMessage(MESSAGES.expiredLink)
      })
    }
  }, [token, tokenValidationDone, isValidRecoveryToken])

  const handlePasswordChange = (event: any) => {
    if (!password || !password.length) {
      pushToDL('form_start', dataLayerData)
    }
    setShowTooltip(false)
    setMessage('')
    setPassword(event.target.value)
  }

  const handleSubmit = async () => {
    if (!meetsAllRequirements(password)) {
      setShowTooltip(true)
      pushToDL('form_submit_error', {
        errorMessage: 'Invalid password entered',
        ...dataLayerData
      })
      setMessage(MESSAGES.passwordNotMeetsAllReqs)
      return
    }
    if (!token) {
      setMessage(MESSAGES.expiredLink)
      return
    }

    const res: any = await changePassword({
      password,
      token
    })
    pushToDL('form_end', dataLayerData)
    let data: any = {}
    if ('data' in res) {
      data = res.data
    }
    if ('error' in res) {
      data = res.error.data
    }

    if (data.status !== 'success') {
      let errorMessage = ''
      if (data && data.errors && data.errors.data && data.errors.data.msg) {
        if (data.errors.data.msg.includes('commonly used passwords')) {
          errorMessage =
            'That password is easily guessed. Please try a more complex one.'
        } else if (
          data.errors.data.msg.includes(
            'Username should not be part of your password'
          )
        ) {
          errorMessage =
            'Your password cannot contain part of your username/email. Please try a different password.'
        } else if (
          data.errors.data.msg.includes(
            'Password cannot be your current password'
          )
        ) {
          errorMessage =
            'Your new password can not be the same as your current or recent password. Please try a different password.'
        } else {
          errorMessage = MESSAGES.resetPasswordFailure
        }
      } else {
        errorMessage = MESSAGES.resetPasswordFailure
      }

      setMessage(errorMessage)
      pushToDL('form_submit_error', { errorMessage, ...dataLayerData })
      setDefaultMessage('')
      return
    }

    setDefaultMessage(MESSAGES.resetPasswordSuccess)
    setMessage('')
    setSuccessfullyReset(true)
  }

  const addEventToLoginLink = (e: any) => {
    addLinkEventsToDL(e, 'All clicks on Login CTA', 'loginBtn', 'text')
  }

  const getFormComponent = () => {
    if (inFlight) {
      return (
        <div className="loader-container">
          <div className="loader">{null}</div>
        </div>
      )
    }

    if (successfullyReset) {
      const query = new URLSearchParams(window.location.search)
      query.delete('token')

      return (
        <div id="loginBtn">
          <a
            href={`/?${query.toString()}`}
            onClick={(e) => {
              addEventToLoginLink(e)
            }}
          >
            <input
              type="button"
              className="btn submit-btn"
              name="Login"
              value="Log In"
            />
          </a>
        </div>
      )
    }

    return (
      <ResetPasswordFormComponent
        showTooltip={showTooltip}
        password={password}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}
      />
    )
  }
  if (!token || (!inFlight && !isTokenValid)) {
    return <LostPassword message={MESSAGES.expiredLink} />
  }

  const formComponent = getFormComponent()

  return (
    <Container>
      <h1>Reset Password</h1>
      <div id="loginMsg" className="login-alert-msg" />
      <div className="rte" />
      <div>
        <p id="message">{message}</p>
      </div>
      <p>{defaultMessage}</p>
      {formComponent}
    </Container>
  )
}
