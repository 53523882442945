import React, { useEffect } from 'react'
import { MEDIA } from '../Constants/Constants'

import SplunkSVG from '../Images/splunk-logo-2.svg'
import SplunkSVGSmall from '../Images/splunk-logo-2-sm.svg'
import EmailVerificationLogo from '../Images/email-verification.svg'
import { Bar, Box, Icon, Message, Modal, SplunkLogo, Title } from './Styled'

export function EmailVerify() {
  useEffect(() => {
    window.dataLayer.push({
      event: 'login',
      status: 'success'
    })
  }, [])
  return (
    <Modal>
      <Bar />
      <Box>
        <SplunkLogo
          srcSet={`${SplunkSVGSmall} 120w, ${SplunkSVG} 120w`}
          sizes={`max-width: ${MEDIA.MAX_WIDTH} 120px, 120px`}
          src={SplunkSVG}
          alt="Splunk Logo"
        />
        <Icon src={EmailVerificationLogo} />
        <Title id="emailVerify" color="#008000">
          A verification email has been sent to you
        </Title>
        <Message>
          {`Check your inbox and verify your email address to get started.
          If you don't see it, please check your spam folder.`}
        </Message>
      </Box>
    </Modal>
  )
}
