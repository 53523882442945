import React, { useState } from 'react'

import { ForcedResetPasswordType } from '../../Types/ForcedResetPasswordType'
import { MESSAGES } from '../../Constants/Constants'
import { ResetPasswordTooltip } from './ResetPasswordTooltip'
import { Container } from '../../Shared/Container'

export function ForcedResetPassword(props: ForcedResetPasswordType) {
  const { password } = props
  const [newPassword, setNewPassword] = useState(password)
  const [showTooltip, setShowTooltip] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [username] = useState('')
  const [message] = useState('')
  const [successfullyReset] = useState(false)
  const [defaultMessage] = useState(MESSAGES.enterNewPassword)

  const handleCurrentPasswordChange = (event: any) => {
    setOldPassword(event.target.value)
  }

  const handleSubmit = (event: any) => {
    // eslint-disable-next-line no-console
    console.log(event, oldPassword, username, successfullyReset)
  }

  const handleNewPasswordChange = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event.target.value)
      return
    }
    setNewPassword(event.target.value)
  }

  const handleFocus = () => {
    setShowTooltip(true)
  }

  const handleBlur = () => {
    setShowTooltip(false)
  }

  return (
    <Container>
      <h1>Reset Password</h1>
      <div id="loginMsg" className="login-alert-msg" />
      <div className="rte" />
      <div>
        <p id="message">{message}</p>
      </div>
      <div>
        <p>{defaultMessage}</p>
      </div>
      <div>
        <div>
          <label htmlFor="email">
            Current Password
            <input
              type="password"
              className="inputBox form-control"
              name="currentPassword"
              id="currentPassword"
              size={25}
              maxLength={255}
              onChange={handleCurrentPasswordChange}
              onKeyDown={handleCurrentPasswordChange}
            />
          </label>
          <label htmlFor="email">New Password</label>
          <div className="forceReset">
            <input
              type="password"
              className="inputBox form-control"
              name="newPassword"
              id="newPassword"
              size={25}
              maxLength={255}
              onChange={handleNewPasswordChange}
              onKeyDown={handleNewPasswordChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <ResetPasswordTooltip
              showTooltip={showTooltip}
              password={newPassword}
              handlePasswordChange={handleNewPasswordChange}
            />
          </div>
          <input
            type="submit"
            className="btn submit-btn btn-bottom-space"
            value="Reset Password"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Container>
  )
}
