import React, { useEffect, useState } from 'react'

import { MESSAGES, EMBARGO_PAGE_URL } from '../../Constants/Constants'
import { LostPasswordType } from '../../Types/LostPasswordType'
import LostPasswordFormComponent from './LostPasswordFormComponent'
import { pushToDL } from '../../Utils/GTM'
import { getLostPasswordMessage, submitWithCaptcha } from '../../Utils/Utils'
import {
  useCsrfTokenQuery,
  useSendLostPasswordEmailMutation
} from '../../Redux/Apis/LoginApi'

import { Container } from '../../Shared/Container'

export function LostPassword(props: LostPasswordType) {
  const [sendLostPasswordEmail] = useSendLostPasswordEmailMutation()
  const { data, isLoading } = useCsrfTokenQuery('')
  const d = useCsrfTokenQuery('')

  const dataLayerData = { fieldName: 'email', formType: 'lost password' }
  const { message: msg } = props
  const { defaultMessage } = MESSAGES
  const [message, setMessage] = useState(msg)
  const [email, setEmail] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [csrf, setCSRF] = useState('')
  const [displayComponent, setDisplayComponent] = useState(true)
  const [displayMessage, setDisplayMessage] = useState(defaultMessage)

  useEffect(() => {
    document.title = 'Reset Your Password | Splunk'
    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.setAttribute(
        'content',
        'Reset the password for your Splunk account.'
      )
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setCSRF(data)
    }
  }, [d, data, isLoading])

  const sendActualLostPassword = async (recaptchaToken: string) => {
    const { location } = window
    const { data: response }: any = await sendLostPasswordEmail({
      email,
      recaptchaToken
    })
    const resMessage = getLostPasswordMessage(email, response)

    if (response.userType === 'dplMatch') {
      // redirect to DPL page
      location.replace(EMBARGO_PAGE_URL)
    } else if (data) {
      setMessage('')
      setDisplayMessage(resMessage)
      setDisplayComponent(false)
    } else {
      setMessage(resMessage)
    }
  }
  const handleSubmit = async () => {
    const isEmployee = email && email.includes('@splunk.com')
    const isEmpty = !email || email.trim().length === 0

    if (isEmployee || isEmpty) {
      setMessage(getLostPasswordMessage(email, false))
      pushToDL('form_submit_error', {
        errorMessage: `${isEmployee ? 'Employee' : 'Empty'} email entered`,
        ...dataLayerData
      })

      return
    }

    await submitWithCaptcha(sendActualLostPassword)
  }

  let lostPasswordComponent
  if (displayComponent) {
    lostPasswordComponent = (
      <LostPasswordFormComponent
        dataLayerData={dataLayerData}
        emailChange={setEmail}
        handleSubmit={handleSubmit}
        actualCallback={sendActualLostPassword}
      />
    )
  }

  return (
    <Container>
      <h1>Reset Password</h1>
      <div id="loginMsg" className="login-alert-msg" />
      <div className="rte" />
      <div>
        <p id="message">{message}</p>
      </div>
      <p>{displayMessage}</p>
      {lostPasswordComponent}
    </Container>
  )
}
