import React from 'react'
import { MEDIA } from '../../Constants/Constants'
import { Bar, Box, Icon, Message, Modal, SplunkLogo, Title } from '../Styled'

import SplunkSVG from '../../Images/splunk-logo-2.svg'
import SplunkSVGSmall from '../../Images/splunk-logo-2-sm.svg'
import Exclamation from '../../Images/exclamation.svg'

export function InvalidToken() {
  return (
    <Modal>
      <Bar />
      <Box>
        <SplunkLogo
          srcSet={`${SplunkSVGSmall} 120w, ${SplunkSVG} 120w`}
          sizes={`max-width: ${MEDIA.MAX_WIDTH} 120px, 120px`}
          src={SplunkSVG}
          alt="Splunk Logo"
        />
        <Icon src={Exclamation} />
        <Title color="#D73030">Expired Link</Title>
        <Message>
          Your verification link has either expired or is no longer valid.
          &nbsp; Please &nbsp;
          <a style={{ color: '#4c8bf5' }} href="/">
            log in
          </a>
          &nbsp; to have a new verification link emailed to you.
        </Message>
      </Box>
    </Modal>
  )
}

export default InvalidToken
