import React from 'react'
import { ResetPasswordTooltipType } from 'src/Types/ResetPasswordTooltipType'
import {
  meetsAllRequirements,
  passwordHasOneUpperAndOneLowercaseLetter,
  passwordIncludesNumber,
  passwordIncludesSpecialCharacter,
  passwordMeetsLengthRequirements
} from '../../Utils/Utils'

export function ResetPasswordTooltip(props: ResetPasswordTooltipType) {
  const { password = '', showTooltip } = props

  let checkMarkLength
  let checkMarkCasing
  let checkMarkNumber
  let checkMarkSpecialChar

  if (passwordMeetsLengthRequirements(password)) {
    checkMarkLength = (
      <span
        id="password-validation-characters"
        className="splunk-icon icon-check"
      />
    )
  }

  if (passwordHasOneUpperAndOneLowercaseLetter(password)) {
    checkMarkCasing = (
      <span
        id="password-validation-uppercase"
        className="splunk-icon icon-check"
      />
    )
  }

  if (passwordIncludesNumber(password)) {
    checkMarkNumber = (
      <span
        id="password-validation-number"
        className="splunk-icon icon-check"
      />
    )
  }

  if (passwordIncludesSpecialCharacter(password)) {
    checkMarkSpecialChar = (
      <span
        id="password-validation-special"
        className="splunk-icon icon-check"
      />
    )
  }

  if (!showTooltip || meetsAllRequirements(password)) {
    return null
  }

  return (
    <div className="resetPasswordToolTip">
      <script type="text/html" id="password-tooltip-template" />
      <div className="tooltip top" role="tooltip">
        <div className="tooltip-arrow" />
        <div className="tooltip-content">
          <div>
            <p>
              Username should <b>not</b> be part of your password.
            </p>
          </div>
          <div>
            <p>Password must include:</p>
          </div>
          <table>
            <tbody>
              <tr>
                <td>{checkMarkLength}</td>
                <td>8-64 characters</td>
              </tr>
              <tr>
                <td>{checkMarkCasing}</td>
                <td>one UPPER and one lower case letter</td>
              </tr>
              <tr>
                <td>{checkMarkNumber}</td>
                <td>one number</td>
              </tr>
              <tr>
                <td>{checkMarkSpecialChar}</td>
                <td>one special character (e.g. $%&#@)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
